export const contentAdvent = [
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [1],
        releaseDate: '2022-12-01',
        content: (
            <div>
                <h4>
                    Write a list of at least 3 things you appreciate about
                    yourself.
                </h4>
                <br />
                <p>
                    Choose a keyword for each and read it out loud, starting
                    with the words "I am...".
                </p>
                <p>
                    Then write it anywhere you'll see at least once a day - it
                    can even be with lipstick on your mirror.{' '}
                </p>
                <p>
                    Or if you want a free personalised wallpaper, just like and
                    comment our post on Instagram!
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [2],
        releaseDate: '2022-12-02',
        content: (
            <div>
                <h4>When was the last time you had a good BELLY laugh?</h4>
                <br />
                <p>
                    Laughter enhances oxygen intake and increases the release of
                    endorphins (the “happy hormone”), boosting the immune
                    system.
                </p>
                <p>
                    And it’s truly contagious, so hit play on a funny podcast,
                    stand-up, or your favourite comedy!
                </p>
                <p>It can even be a funny Instagram page.</p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [3],
        releaseDate: '2022-12-03',
        content: (
            <div>
                <h4>Foot massage can be extremely pleasurable and relaxing.</h4>
                <br />
                <p>
                    And the best part, is you don't need to have a partner or a
                    massagist to get the benefits of it.
                </p>
                <p>
                    After showering, while you're still warm, start pressuring
                    your foot with your fingers, from soft to hard movements,
                    taking your time, no rush.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [4],
        releaseDate: '2022-12-04',
        content: (
            <div>
                <h4>
                    Eat your favourite meal of the day with no rush and without
                    any distractions.
                </h4>
                <br />
                <p>
                    And if you’re going to cook today, do it mindfully. As if
                    you're giving yourself a foreplay for your taste.
                </p>
                <p>Taste the ingredients. </p>
                <p>Lick that spoon. Slowly. </p>
                <p>Enjoy your taste buds going crazy.</p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [5],
        releaseDate: '2022-12-05',
        content: (
            <div>
                <h4>Set 15 min. to rejoice in your happiest memories.</h4>
                <br />
                <p>
                    Scroll through your camera roll looking for moments when
                    you're feeling great!
                </p>
                <p>
                    Save them in an album that you can revisit whenever you need
                    a shot of endorphins from the past.
                </p>
                <p>
                    It can be called 'Loving Myself' or any other name that
                    resonates with you.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [6],
        releaseDate: '2022-12-06',
        content: (
            <div>
                <h4>Inhale pleasure every chance you can.</h4>
                <br />
                <p>
                    Before putting on a perfume or a scented moisturising cream,
                    set 5 min. to immerse yourself in that smell.{' '}
                </p>
                <p>
                    If you don't have either, make an aromatic drink (e.g. tea,
                    coffee or chai) and before drinking it, set this time to
                    take in all that this scent can provide.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [7],
        releaseDate: '2022-12-07',
        content: (
            <div>
                <h4>
                    Have you ever tuned in to how pleasurable it is the water
                    touching your skin?
                </h4>
                <br />
                <p>
                    That flowing stream is a pouring pleasure that goes
                    unnoticed most of the time.
                </p>
                <p>
                    Today when you shower, immerse in the experience of feeling
                    the water caressing your whole body.
                </p>
                <p>Feel its warmth spreading on your skin.</p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [8],
        releaseDate: '2022-12-08',
        content: (
            <div>
                <h4>Take a moment to admire the full moon and stars.</h4>
                <br />
                <p>
                    Connect with our ancient women that were guided by the full
                    moon when we didn't have electric energy.
                </p>
                <p>
                    Imagine you were living in the forest and the moon was
                    lightening your path.
                </p>
                <p>
                    And if the sky is not clear tonight, watch a beautiful
                    timelapse of the stars and moon.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [9],
        releaseDate: '2022-12-09',
        content: (
            <div>
                <h4>
                    You don't need to sound good to feel good when you sing!
                </h4>
                <br />
                <p>
                    Studies show it relieves stress and pain, boosts energy and
                    confidence, strengthens the immune system, and improves
                    breathing and mindfulness - key ingredients for more
                    pleasure in sex and life.
                </p>
                <p>So play your favourite song and sing along on your own!</p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [10],
        releaseDate: '2022-12-10',
        content: (
            <div>
                <h4>Self-hug may seem odd, but it has proven benefits!</h4>
                <br />
                <p>
                    It relieves pain, increases sense of security and
                    self-compassion, and improves your mood.
                </p>
                <p>
                    Human touch, even your own, releases oxytocin (aka love
                    hormone) and lowers cortisol (aka stress hormone).{' '}
                </p>
                <p>
                    What a powerful switch! So take a whole 1min. today to
                    self-hug.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [11],
        releaseDate: '2022-12-11',
        content: (
            <div>
                <h4>
                    Audio can give you the same level of immersion and
                    engagement as a visual stimulus.
                </h4>
                <br />
                <p>
                    Plus, it leaves room for your imagination to fill the gaps
                    with your own preferences!
                </p>
                <p>So give audio erotica a try today. </p>
                <p>
                    There are apps just focused on this (Quinn, Dipsea, OhCleo)
                    or you can even start an audiobook!
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [12],
        releaseDate: '2022-12-12',
        content: (
            <div>
                <h4>Try on outfits that make you feel sexy.</h4>
                <br />
                <p>
                    Be that a loose old t-shirt and comfy socks, or putting on
                    fishnet tights and red lipstick.
                </p>
                <p>All it matters is that you feel good with them.</p>
                <p>
                    End in your most empowering look and contemplate yourself in
                    the mirror for a whole minute.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [13],
        releaseDate: '2022-12-13',
        content: (
            <div>
                <h4>
                    Pompoir (aka Kegel) has a bad reputation as being only for
                    pregnancy or to "please a man".
                </h4>
                <br />
                <p>
                    But it can improve anyone's health and libido! The real
                    benefits are felt after continuous exercise, but you can
                    still discover this power today!
                </p>
                <p>
                    You know that muscle you use to hold pee? Try to contract it
                    while masturbating. This pumps blood to your clitoris,
                    making you more aroused!
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [14],
        releaseDate: '2022-12-14',
        content: (
            <div>
                <h4>Mirror work is a powerful way to cultivate self-love.</h4>
                <br />
                <p>
                    Gaze at yourself while saying positive affirmations such as
                    “I am at home in my body”.
                </p>
                <p>
                    The first times can be triggering, so be kind to yourself
                    and see this as expelling external judgement that has been
                    infected on you. Once this is cleared out, you’ll see the
                    perfectly gorgeous being that you are.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [15],
        releaseDate: '2022-12-15',
        content: (
            <div>
                <h4>Make time to smell the flowers today.</h4>
                <br />
                <p>
                    Even during winter, there are plants with delicious smells,
                    like lavender. And did you know the scent is on its leaves
                    too? Rub them with your fingers to release a more intense
                    smell.
                </p>
                <p>
                    If there are no flowers on your way, enter a garden shop or
                    any supermarket that sells it!
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [16],
        releaseDate: '2022-12-16',
        content: (
            <div>
                <h4>Read it on</h4>
                <br />
                <p>
                    <a
                        href="https://www.instagram.com/freeyourvulva"
                        target="_blank"
                        rel="noreferrer"
                    >
                        our Instagram page!
                    </a>
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [17],
        releaseDate: '2022-12-17',
        content: (
            <div>
                <h4>Feel the delicious pleasure of a sweet taste</h4>
                <br />
                <p>
                    For Bea, it’s hard to beat the pleasure of slowly tasting a
                    piece of dark chocolate, and letting it melt in her mouth.
                    Is there anything sexier than that?
                </p>
                <p>
                    And if you can’t have sugar because of dietary reasons like
                    Paula, why not try sugar-free alternatives? Message us for
                    recommendations!
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [18],
        releaseDate: '2022-12-18',
        content: (
            <div>
                <h4>
                    Massage is a great way to get in touch with your own body,
                    both physically and spiritually.
                </h4>
                <br />
                <p>Sometimes we hold tension without even realising it.</p>
                <p>
                    So having a pleasurable self-massage with oil can bring
                    awareness to our muscles, and improve circulation and pain
                    relief.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [19],
        releaseDate: '2022-12-19',
        content: (
            <div>
                <h4>Play a sexy song and put on a show to yourself.</h4>
                <br />
                <p>
                    Dance like nobody (or everyone, if that’s what turns you
                    own) is watching.
                </p>
                <p>
                    If you don’t know which song will bring up your wildest and
                    most liberated self, explore some playlists by searching for
                    “sexy”, “sensual” and “pleasure”.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [20],
        releaseDate: '2022-12-20',
        content: (
            <div>
                <h4>Don’t limit this stimulation to your taste only.</h4>
                <br />
                <p>
                    Envision what is your idea of a perfect date and set a time
                    on your calendar to do it on your own.
                </p>
                <p>
                    Beyond eating out, take time to visualise all that brings
                    you joy.
                </p>
                <p>
                    Our personal favourites are watching live music, going to an
                    exhibition and dancing.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [21],
        releaseDate: '2022-12-21',
        content: (
            <div>
                <h4>
                    Depict your vulva, as literal or as abstract as you want, in
                    whatever method is available to you!
                </h4>
                <br />
                <p>
                    It can be writing, drawing, photographing, or even a
                    collage.
                </p>
                <p>
                    The important part is gazing at your vulva long enough so
                    you feel you have a clear mental picture of all of its
                    beautiful parts.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [22],
        releaseDate: '2022-12-22',
        content: (
            <div>
                <h4>Yes, you heard it right.</h4>
                <br />
                <p>
                    It's time for you and your body to spend some time together
                    (20 minutes or more).
                </p>
                <p>
                    Set the ambience, light up some candles, and play some sexy
                    songs.
                </p>
                <p>
                    Explore every tiny part of your body, from head to toe,
                    leaving your vulva to the end. Enjoy your own touch.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [23],
        releaseDate: '2022-12-23',
        content: (
            <div>
                <h4>
                    It can feel uncomfortable at first, but it’s a powerful
                    exercise of body acceptance and celebration!
                </h4>
                <br />
                <p>
                    Don’t want your nudes in the cloud and only have a phone
                    camera?
                </p>
                <p>
                    Go offline while you take them and transfer them to your
                    laptop with a cable!
                </p>
                <p>
                    You can even delete them afterwards but take at least 10
                    poses.
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [24],
        releaseDate: '2022-12-24',
        content: (
            <div>
                <h4>Humans have benefited from aromas since ancient times.</h4>
                <br />
                <p>
                    Resins, balms, and oils can heal and treat our bodies and
                    mind. And even an everyday smell has the power to instantly
                    make you feel good. So today, fill your home with the scent
                    of candles, comfort food, tea... Any smell that brings you
                    pleasure!
                </p>
                <p>
                    Christmas Eve can be a busy day, so we wanted our last day
                    to be a simple act. We hope it's a weekend full of
                    opportunities to savour delicious smells! From cinnamon, to
                    gingerbreads, to a fireplace... Enjoy it and we wish you the
                    most pleasurable holiday!
                </p>
            </div>
        ),
    },
    {
        title: 'F.Y.V. Advent Calendar',
        imgList: [25],
        releaseDate: '2022-12-25',
        content: (
            <div>
                <h4>We hope you enjoyed these...</h4>
                <br />
                <p>
                    24 simple yet powerful ways to expand your capacity of
                    experiencing pleasure and self-love.
                </p>
                <p>
                    Let us know which ones were your favourites{' '}
                    <a
                        href="https://www.instagram.com/freeyourvulva"
                        target="_blank"
                        rel="noreferrer"
                    >
                        our Instagram page!
                    </a>
                </p>
            </div>
        ),
    },
];
