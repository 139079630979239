export const contentPosts = [
    // {
    //     title: "My first talk - “A Case for Body Neutrality”",
    //     imgList: [143, 144, 145, 146],
    //     content: (
    //         <div>
    //             <h4>My first talk - “A Case for Body Neutrality”</h4>
    //             <br />
    //             <p>
    //                 Earlier this year, I had the pleasure and privilege to do my
    //                 first talk - “A Case for Body Neutrality”. I'll share key
    //                 takeaways in another post, but this one is about following a
    //                 passion - without expecting any outcome to keep pursuing it.
    //             </p>
    //             <p>
    //                 I was raised to think that having expectations is what leads
    //                 to action. But Buddhism says it leads to suffering - and
    //                 today, I couldn’t agree more. That’s what I experienced last
    //                 year. Despite the encouragement I received, I still felt
    //                 like a failure because I wanted to reach way more people.
    //                 And it’s natural to lose motivation when you don’t have the
    //                 impact you dream of. But how liberating it could be to only
    //                 dream to see yourself free from expectations?
    //             </p>
    //             <p>
    //                 If you want anything beyond what you already have, your
    //                 current state is not complete. “As if the baseline is
    //                 negative, not zero. So that you're constantly using your
    //                 life experiences as a way to disprove a basic negative
    //                 feeling about yourself.” - Ram Dass
    //             </p>
    //             <p>
    //                 When you’re working from a place of not expecting anything
    //                 instead, you’re setting yourself for a guaranteed win-win
    //                 situation. You start from a neutral point, where anything
    //                 you get will always add up to a positive end. It becomes a
    //                 bonus instead of a need. So today, all that I intend to put
    //                 myself out there - expecting nothing in return.
    //             </p>
    //             <p>
    //                 It's truly liberating to receive recognition from this new
    //                 mindset. And here is some of the feedback I got (thanks
    //                 everyone for all the support 😍):
    //             </p>
    //             <p>
    //                 “Paula CRUSHED IT (respectfully.). Her presentation was
    //                 super informative and wildly inspiring. Paula interwove the
    //                 history of women's bodies alongside personal examples of her
    //                 own journey with self-acceptance, which enabled the group to
    //                 feel comfortable enough to share their own stories. Paula,
    //                 thank you for creating a space where anyone, regardless of
    //                 gender/sexual identity, could connect with fellow colleagues
    //                 to raise awareness of body neutrality. You are a lightning
    //                 rod for positivity, and I'm so glad you are here.” - Lotti H
    //             </p>
    //             <p>
    //                 “It was really powerful. I learnt a lot about the context of
    //                 the feminist movements and it was very eye-opening. You're
    //                 so fearless and confident! Despite being such a hard topic
    //                 to address." - Chung Lo
    //             </p>
    //         </div>
    //     ),
    // },
    {
        title: 'Brain-rewiring with art',
        imgList: [138, 139, 140, 141, 142],
        content: (
            <div>
                <h4>Brain-rewiring with art</h4>
                <br />
                <p>
                    Rewiring negative associations with our bodies can come from
                    art. Today, I see a vulva as delicious as a moist cake. And
                    a labia as beautiful as flower petals. But that wasn’t
                    always the case.
                </p>
                <p>
                    It was during a drawing exercise of staring at my vulva -
                    and confronting my shame -, that I had one of the most
                    transformative epiphanies. I realised it is perfectly
                    asymmetrical just like a flower! That might not seem like a
                    big discovery for some, but I honestly had never made that
                    connection before. One that completely shifted my perception
                    of vulvas from aversion to the deepest admiration. And I
                    can’t help of wonder if I had always been exposed to vulva
                    art and imagery, would I have ever felt any shame? Would I
                    ever think I was straight?
                </p>
                <p>
                    So today I share the erotic surrealism of Joyce Lee. In an
                    interview with Dazed, she said: "This sensation of being
                    challenged in the way we normally see and experience the
                    world is something I love. (...) I explore themes of gender,
                    identity, equality, and sometimes empowerment through visual
                    metaphors. I want the women inside my art to be liberated
                    and I want them to express their feelings freely with their
                    bodies.”
                </p>
                <p>
                    These are just a few of my favourites, but you can see so
                    much more on her page @joyceartworks
                </p>
            </div>
        ),
    },
    {
        title: 'From shame to Pride',
        imgList: [137],
        content: (
            <div>
                <h4>🌈 From shame to Pride </h4>
                <br />
                <p>
                    There are way better-equipped people to talk about Pride in
                    all of its magnitude. So instead, I decided to share my own
                    journey, from shame to pride. 🌈❤️
                </p>
                <p>
                    Just over a year ago, I felt “I hadn’t earned the right to
                    identify myself as nothing but straight. How could I since I
                    hadn’t even experienced anything else?”. Those were my exact
                    words, but I can barely recognise them as my own. So much so
                    that I’m wrapping them in quotes, as I feel they belong to
                    my past self only. One that was driven by involuntary and
                    uncontrollable guilt. Probably from carrying shame about my
                    vulva for 30 years...
                </p>
                <p>
                    Luckily, I’m surrounded by amazing people that helped me to
                    overcome this. One of my dearest friends, Henrique, very
                    wisely told me: “You’re not asexual when you’re still a
                    virgin. You knew you were attracted to men even then. So why
                    do you feel you need to be with a woman to validate your
                    sexuality? Nobody will give you a bisexuality card”. I
                    laughed and I agreed that was true. But it was a whole other
                    process to integrate this as my own truth.
                </p>
                <p>
                    It was a while afterwards when I went to this immersive
                    installation called Dreamachine, where lights induced an
                    altered state of consciousness. And only in this state, I
                    could finally and truly embrace what I already knew in my
                    heart to be true. And let go of the need for any experience
                    to validate my sexuality.
                </p>
                <p>
                    I believe in the future we won’t need labels to define
                    ourselves. I think it’s rather limiting to try to
                    encapsulate the complexity of our ever-changing existence.
                    But I understand why we still need them. So today, I can
                    proudly say I’m pansexual. And that I have such an
                    incredible, empowered and inspiring woman as my girlfriend.
                    Bruna, I hope you take this month to celebrate your journey.
                    Of someone who always had the awareness to know your truth.
                    The courage to express it. And the strength to live it.
                </p>
                <p>
                    If shame is what is stopping you from exploring or owning
                    your sexuality, honour this month by cultivating pride for
                    however you identify. And hopefully, you'll feel the
                    #queerjoy of being unapologetically yourself. 🏳️‍🌈🏳️‍⚧️
                </p>
            </div>
        ),
    },
    {
        title: 'Vulva Ceramics',
        imgList: [133, 134, 135],
        content: (
            <div>
                <h4>Vulva Ceramics</h4>
                <br />
                <p>
                    I want to paint the wall above my bed with, no wonder, a
                    huge vulva. I keep going back and forth with this since I
                    don’t think my landlord will be pleased with my art 🖌️
                </p>
                <p>
                    Even so, I've been researching some inspiration and I found
                    this gorgeous vulva ceramics by @martine.othilie that I
                    wanted to share 🐚 ✨
                </p>
            </div>
        ),
    },
    {
        title: 'Lady Phyll',
        imgList: [132],
        content: (
            <div>
                <h4>Lady Phyll</h4>
                <br />
                <p>
                    Earlier this year, I became the Women’s Network chair at the
                    company I work for - MVF. And thanks to this role, I had the
                    honour of bringing an absolute legend to give an IWD talk -
                    @ladyphyll. I know it’s a bit late for this post, but as she
                    pointed out, IWD is every day - we don’t stop being women
                    for the rest of the year. Besides, a single day is certainly
                    not enough for all the conversations and celebrations we
                    must have.
                </p>
                <p>
                    And what a woman to be celebrated! Phyll Opoku-Gyimah is an
                    award-winning British political activist, with over two
                    decades of experience leading transformative organisations,
                    building sustainable movements for change and fighting
                    against the systemic injustices impacting Black LGBTQI+
                    people and people of colour. 🖤
                </p>
                <p>
                    She is the co-founder of UK Black Pride, an event which will
                    be celebrating its 18 years on 20th August. Save the date so
                    you don’t miss out on the world’s largest celebration for
                    African, Asian, Middle Eastern, Latin American and
                    Caribbean-heritage LGBTQI+ people! 🌈 And if you’re unable
                    to attend but can support it, donate through ukblackpride
                </p>
                <p>
                    If that wasn’t enough, she’s also an executive director of
                    Kaleidoscope Trust, a nonprofit organisation that campaigns
                    for the human rights of LGBT+ people around the world. 🏳️‍🌈🏳️‍⚧️
                    Read more about their work and ways to support them at
                    @kaleidoscope_t
                </p>
                <p>
                    My deepest gratitude to you, Lady Phyll. For sharing your
                    precious time and immense wisdom with us. For being a source
                    of inspiration on how to be unapologetically yourself. And
                    for driving us to also become a positive force for change.
                </p>
                <p>📷 Photo by Kofi Paintsil</p>
            </div>
        ),
    },
    {
        title: "International Women's Day",
        imgList: [131],
        content: (
            <div>
                <h4>International Women's Day</h4>
                <br />
                <p>
                    "Imagine a gender equal world. A world free of bias,
                    stereotypes, and discrimination. A world that's diverse,
                    equitable, and inclusive. A world where difference is valued
                    and celebrated. Together we can forge women's equality.
                    Collectively we can all #EmbraceEquity.
                </p>
                <p>
                    Celebrate women's achievement. Raise awareness about
                    discrimination. Take action to drive gender parity.
                </p>
                <p>
                    IWD belongs to everyone, everywhere. Inclusion means all IWD
                    action is valid."
                </p>
                <p>
                    <a
                        href="https://www.internationalwomensday.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        By International Women's Day
                    </a>
                </p>
            </div>
        ),
    },
    {
        title: 'Vulversary',
        imgList: [130],
        content: (
            <div>
                <h4>Vulversary</h4>
                <br />
                <p>
                    Last weekend I celebrated the day I fell in love with my
                    vulva - and consequently, with myself. I named it my
                    Vulversary. Coming from a place of nonexistent self-esteem,
                    it took me a tremendous amount of self-work to get here. The
                    last piece missing to be able to embrace me completely was
                    letting go of ingrained shame about my vulva. So when I
                    first looked at "her" with nothing but admiration, it was
                    truly transformative. So much I promised to commemorate it
                    every year by taking her out on a night filled with my
                    favourite pleasures.
                </p>
                <p>
                    This moment unlocked a radical self-love that I thought
                    could never be shaken. But last year, a traumatic experience
                    left me completely shattered and unable to feel
                    self-compassion, let alone any love. After a lot of
                    processing and healing, I eventually picked up the pieces. I
                    then felt stronger than ever and so proud of being able to
                    recover from this - to the extent of creating the illusion
                    that nothing could make me lose myself again. And it’s true
                    that these moments were definitely turning points as there
                    is no way back to the same insecurity I once had. But yet,
                    after some more challenging experiences, I saw the
                    self-doubt returning. This time I was able to navigate it
                    with more clarity and acceptance, seeing it as a lingering
                    side effect of trauma, not as my true self. I knew the
                    feelings of disconnection, apathy and not believing in me
                    would not last forever. But there was still this underlying
                    feeling of failure.
                </p>
                <p>
                    I was still in this headspace when this year's Vulversary
                    came. So I felt like an impostor for trying to celebrate
                    myself when I couldn’t genuinely feel there was anything
                    worth celebrating. Even my libido has been down, so how
                    could I talk about the power of pleasure if I couldn’t
                    connect with that? But despite it all, I had promised to
                    commemorate every year, so I honoured that commitment to
                    myself. I decided to watch a concert and go out dancing -
                    all alone. And it was a real effort, but during this
                    process, I found myself again. After months, I finally
                    reignited my light and restored my self-love. And I know now
                    it's never-ending work to maintain this, but there is no
                    work more worthwhile or rewarding.
                </p>
            </div>
        ),
    },
    {
        title: 'What the future holds',
        imgList: [129],
        content: (
            <div>
                <h4>What the future holds</h4>
                <br />
                <p>
                    We've been taking some needed time to recharge and
                    reflect...!
                </p>
                <p>
                    We committed ourselves to delivering everything we
                    envisioned for last year and we're proud to say we did
                    accomplish that. We didn't reach as many as we dreamt of,
                    but the feedback we did receive made it all worth it.
                    However, after the FYV Advent Calendar, it became clear that
                    the amount of time and energy we had been investing was not
                    sustainable for us. Curating and creating content only in
                    our free time, while navigating the inherent challenges of
                    social media, as well as the ones specific to the s3x
                    education, became unfeasible... 😔
                </p>
                <p>
                    Sometimes we need to take one step back to continue moving
                    forward. So we decided that I (Paula) will give continuity
                    to the project on a smaller scale from now on. In a
                    frequency yet to be discovered, since I still need to learn
                    the balance of pursuing a passion project without taking a
                    toll on my mental health. My next Ayahuasca journey will
                    hopefully show me the way... 🍃
                </p>
                <p>
                    Bea realised that this cycle had come to an end for her. I
                    will be forever grateful for her believing in the idea and
                    being a driving force to get this project off the ground.
                    For giving me her blessing and encouragement to continue FYV
                    on my own. For being next to me through thick and thin. For
                    helping me find myself again when I was lost. And for being
                    a gateway for so much self-love and pleasure in my life.
                    With the deepest admiration and love, thank you Bea for
                    everything ❤️
                </p>
            </div>
        ),
    },
    {
        title: 'TIME TO CELEBRATE THE SH*T OUT OF OUR VULVAS',
        imgList: [126, 127, 128],
        content: (
            <div>
                <h4>TIME TO CELEBRATE THE SH*T OUT OF OUR VULVAS!</h4>
                <br />
                <p>
                    @eloinaaart is back with another edition of High Steaks,
                    from Jan 24th-27th 🔥
                </p>
                <p>
                    The show is a riotous rebellion against labia-shaming and
                    cosmetic Labiaplasty. Labiaplasty is the surgery to make the
                    labia smaller/more symmetrical. Medical professionals blame
                    the increasing numbers of this surgery on the lack of
                    visibility of varied vulvas.
                </p>
                <p>
                    So in the words of Eloina herself: "IT’S TIME TO CELEBRATE
                    THE SH*T OUT OF OUR VULVAS. To raise a glass to your labia &
                    the labia of loved ones. To raise a middle finger to
                    butchering your body for the aesthetics of another."
                </p>
                <p>
                    The performance plays testimonies of people who experienced
                    labia shaming and Paula had the honour to be one of them.
                    “The show took me through a vast plethora of emotions. From
                    laughter to sadness, from confrontation, to release.
                    Listening to my own history, intertwined with Eloina's and
                    other people's experiences, was truly cathartic. I can't
                    express enough gratitude and admiration for such a brilliant
                    and brave human as Eloina.”
                </p>
                <p>
                    We can't recommend it enough, so check her page for tickets!
                </p>
            </div>
        ),
    },
    // {ADVENT CALENDAR POSTS}
    {
        title: 'We have a gift for you',
        imgList: [113, 114, 115, 116, 117],
        content: (
            <div>
                <h4>We have a gift for you!</h4>
                <br />
                <p>
                    This December, Free Your Vulva brings to you a... (vulva
                    rolls 🥁)...
                </p>
                <p>🤎 👄 ✨ PLEASURE & SELF-LOVE ADVENT CALENDAR</p>
                <p>
                    We'll share daily invitations to unlock new levels of body
                    stimulation, awareness and satisfaction.
                </p>
                <p>
                    24 days to awaken your senses and expand your capacity of
                    experiencing pleasure and self-love.
                </p>
                <p>
                    Follow our page to find out all the simple yet powerful ways
                    to treat your body and soul.
                </p>
                <p>
                    <span>
                        2023 Update - Happy New Year! With the Advent period
                        being over, all content is now available in here:
                    </span>
                    <br />
                    <a
                        href="https://freeyourvulva.com/self-work"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://freeyourvulva.com/self-work
                    </a>
                </p>
            </div>
        ),
    },
    {
        title: 'We’re celebrating 6 months of Free Your Vulva!',
        imgList: [111, 112],
        content: (
            <div>
                <h4>We’re celebrating 6 months of Free Your Vulva! 🎉</h4>
                <br />
                <p>
                    These pics are from when we launched the project to some of
                    our biggest supporters (and beloved friends). They’ve always
                    been by our side, pushing us to move forward and spread the
                    word. So the least we could do was to treat them with
                    Brazilian vegan cheese breads, in the shape of a vulva
                    (handmade by Paula).
                </p>
                <p>
                    It’s not easy to keep an Instagram account with fresh
                    content, follow the algorithm changes, and watch out not to
                    get banned. But having a platform to talk about female
                    empowerment is what keeps us moving.
                </p>
                <p>
                    And to thank everyone who believes in and supports our
                    project, we’ve got you a special Christmas gift! Keep tuned,
                    it’s coming on December 1st. And we know you’ll love every
                    piece of it. ❤️
                </p>
            </div>
        ),
    },
    {
        title: 'To Go - Barbican',
        imgList: [110],
        content: (
            <div>
                <h4>To Go - Barbican</h4>
                <br />
                <p>
                    The Barbican is one of the UK’s architectural treasures.
                    Apart from its iconic brutalist architecture, it also hosts
                    amazing exhibitions. From now until the beginning of 2023,
                    there are 2 female artists that are worth checking:
                </p>
                <br />
                <p>
                    <a
                        href="https://www.barbican.org.uk/whats-on/2022/event/carolee-schneemann-body-politics"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Exhibition: Carolee Schneemann, Body Politics
                    </a>
                </p>
                <p>
                    @schneemannfoundation When? Thu 8 Sep 2022—Sun 8 Jan 2023,
                    Art Gallery About: The first major survey of Carolee
                    Schneemann’s work in the UK, tracing her diverse,
                    transgressive and interdisciplinary expression over six
                    decades. Schneemann was a radical artist who remains a
                    feminist icon and point of reference for numerous
                    contemporary artists to this day. Addressing urgent topics
                    from sexual expression and the objectification of women to
                    human suffering and the violence of war, her work is
                    concerned with the precarious lived experience of humans and
                    animals. With over 200 objects and rarely seen archival
                    material, this exhibition positions Schneemann as one of the
                    most relevant, provocative and inspiring artists of the
                    twentieth and early twenty-first centuries.
                </p>
                <br />
                <p>
                    <a
                        href="https://www.barbican.org.uk/whats-on/2022/event/soheila-sokhanvari-rebel-rebel"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Exhibition: Soheila Sokhanvari, Rebel Rebel
                    </a>
                </p>
                <p>
                    @soheila_sokhanvari_ When? Thu 8 Sep 2022—Sun 8 Jan 2023,
                    Art Gallery About: Rebel Rebel, the first major UK
                    commission by Iranian artist Soheila Sokhanvari, celebrates
                    and commemorates feminist icons from pre-revolutionary Iran.
                    Sokhanvari transforms the Curve into a devotional space,
                    populated with exquisite miniature portraits of glamorous
                    cultural figures from Iran. The project spotlights the
                    rarely told histories of these women, who pursued creative
                    careers in a culture enamoured with Western style but not
                    its freedoms.
                </p>
            </div>
        ),
    },
    {
        title: 'High Steaks',
        imgList: [108, 109],
        content: (
            <div>
                <h4>High Steaks</h4>
                <br />
                <p>
                    Last summer, @eloinaaart was looking for women to be
                    interviewed about Vulva shame and Paula had the honour to be
                    one of them. She recorded their testimonies and played
                    during her performance, High Steaks.
                </p>
                <p>
                    “The show took me through a vast plethora of emotions. From
                    laughter, to sadness, from confrontation, to release.
                    Listening to my own history, intertwined with Eloina's and
                    other women's experiences, was truly cathartic. I can't
                    express enough gratitude and admiration for such a brilliant
                    and brave human as Eloina.” - Paula
                </p>
                <p>
                    High Steaks is riotous rebellion against labia-shaming and
                    cosmetic Labiaplasty. Labiaplasty is the surgery to make the
                    labia smaller/more symmetrical. Medical professionals blame
                    the increasing numbers of this surgery on the lack of
                    visibility of varied vulvas.
                </p>
                <p>
                    So in the words of Eloina herself: "IT’S TIME TO CELEBRATE
                    THE SH*T OUT OF OUR VULVAS. To raise a glass to your labia &
                    the labia of loved ones. To raise a middle finger to
                    butchering your body for the aesthetics of another."
                </p>
            </div>
        ),
    },
    {
        title: 'Rupi Kaur',
        imgList: [103, 104, 105, 106, 107],
        content: (
            <div>
                <h4>Rupi Kaur</h4>
                <br />
                <p>
                    I am a big fan of poetry (Be). When I was a child, I loved
                    writing little poems on napkins, or a piece of paper,
                    whenever I got inspired. Although I don’t practice this
                    beautiful art expression as much as I’d like, I admire great
                    poets of our time, such as Rupi Kaur (@rupikaur_).
                </p>
                <p>
                    Rupi's work has inspired and empowered women all over the
                    world. It touches on love, loss, trauma, healing,
                    femininity, and migration. Her books have sold over 10
                    million copies and been translated into over 42 languages.
                </p>
                <p>
                    She also created this beautiful sweater with 6 uniquely
                    shaped vulvas embroidered on the front and the 'oh but the
                    pussy is brave' on the back. If that wasn’t enough for you
                    to love it, it’s also ethically sourced and sustainably made
                    💚
                </p>
                <p>
                    She is now on a world tour, performing her poetry on stage.
                    Check her work and itinerary here:{' '}
                    <a
                        href="https://rupikaur.com/pages/world-tour"
                        target="_blank"
                        rel="noreferrer"
                    >
                        https://rupikaur.com/pages/world-tour
                    </a>
                </p>
            </div>
        ),
    },
    {
        title: 'Feminine Power - The divine to the demonic',
        imgList: [97, 98, 99, 100, 101, 102],
        content: (
            <div>
                <h4>Feminine Power - The divine to the demonic</h4>
                <br />
                <p>
                    We were delighted to visit an impressive exhibition called
                    Feminine Power - The divine to the demonic, at the British
                    Museum. We spent over an hour looking at beautiful
                    sculptures, sacred objects and artworks from the ancient
                    world to today, from 6 continents. The exhibition explores
                    the vast and significant roles that goddesses, demons,
                    witches, spirits and saints have played – and continue to
                    play – in shaping our understanding of the world.
                </p>
                <p>
                    As the exhibition explained: “engaging with the world’s many
                    cultures and spiritual traditions across time shows that a
                    wide range of beliefs about feminity has always existed.
                    Those beliefs can help us reflect on our ideas and
                    preconceptions of what feminine power means today.”
                </p>
                <p>
                    Most women are raised with the idea that being feminine is
                    only being a life-giver, in nurturing and very passive
                    roles. But what we saw there were extremely powerful and
                    active feminine figures. Warriors that evoke ferocity,
                    destruction and rage. Figures that embody passion, pleasure
                    and creativity.
                </p>
                <p>
                    And as beautifully said by Rabia Siddique, “we need to
                    embrace the full breath and measure of our power in all of
                    its multi-dimensions, to be willing and able to be
                    life-givers, to be mothers, but also to be lionesses and
                    warriors, to be advocates, to be change agents.”
                </p>
                <p>
                    Scroll through this post to read more about our favourite
                    goddesses. And{' '}
                    <a
                        href="https://www.britishmuseum.org/exhibitions/feminine-power-divine-demonic"
                        target="_blank"
                        rel="noreferrer"
                    >
                        get your tickets
                    </a>{' '}
                    before they sell out! It’s running only until September
                    25th, so make sure you don’t miss out!
                </p>
            </div>
        ),
    },
    {
        title: 'The Vulva Gallery',
        imgList: [89, 90, 91, 92, 93, 94, 95, 96],
        content: (
            <div>
                <h4>The Vulva Gallery</h4>
                <br />
                <p>
                    Celebrating vulva diversity, @the.vulva.gallery is a page
                    created by @hildeatalanta, an amazing Illustrator and oil
                    painter.
                </p>
                <p>
                    Our hearts get filled with happiness when we see that more
                    than 780k people follow this page and have access to such a
                    wide variety of vulvas!
                </p>
                <p>
                    Did you love it as much as we did? You can support the
                    artist by following their Instagram page @the.vulva.gallery
                    or buying one of the amazing products! Swipe to see our
                    personal favourites, but do also check their{' '}
                    <a
                        href="https://www.thevulvagallery.com/webshop"
                        target="_blank"
                        rel="noreferrer"
                    >
                        store
                    </a>{' '}
                    for even more!
                </p>
            </div>
        ),
    },
    {
        title: 'Festivals, body positivity & liberation',
        imgList: [83, 84, 85, 86, 87],
        content: (
            <div>
                <h4>Festivals, body positivity & liberation</h4>
                <br />
                <p>
                    Last month, Paula had the privilege of attending
                    @secretgardenparty. A lot has been said about its poor
                    infrastructure, but not enough about the amazing people that
                    made the festival so worth it and magical even so. She said:
                </p>
                <p>
                    "It was so liberating to be in the presence of confident
                    women owning and loving their bodies, and that inspired me
                    to free my body even more. Coming from a country where I
                    couldn’t even show my legs without being extremely
                    sexualised, I can’t explain the freedom and power that I
                    felt from being topless in a festival”.
                </p>
                <p>
                    If that wasn’t enough, she also had the honour to meet one
                    of our inspiring muses there, @lydiareeves_artist ! A Female
                    Body Casting Artist, Lydia allows people to celebrate their
                    incredible bodies, and helps to eradicate any shame,
                    embarrassment or negative thoughts that they may have
                    encountered throughout their lives.
                </p>
                <p>
                    And bringing body positivity and liberation to a new level,
                    Paula also had the absolute pleasure of attending
                    @bodymovementsfestival last month. The event is described as
                    "Queer Bodies in Electronic Music". But she felt it should
                    be sold as "The utopia of free bodies". She said:
                </p>
                <p>
                    “The energy there was exhilarating and revitalising. I’ve
                    never been surrounded by so many authentic and liberated
                    people, who were celebrating themselves and each other.
                    Walking with just a nipple cover in plain delight in Hackney
                    Wick felt surreal. And this feeling says a lot about how far
                    we are from being able to show and own our bodies however
                    and wherever we chose. As long as something as natural as
                    showing our skin feels surreal, we are still confined. So
                    thank f*ck for Secret Garden Party and Body Movements! For
                    giving me an escape from this sexually repressed society.
                    For giving me the sweet taste of this liberated future that
                    we’re working towards ❤️”.
                </p>
            </div>
        ),
    },
    {
        title: 'Erika Lourenço',
        imgList: [79, 80, 81, 82],
        content: (
            <div>
                <h4>Erika Lourenço</h4>
                <br />
                <p>
                    @erika.lourencoo is an artist and illustrator, and she is
                    responsible for creating those beautiful vulva & clit art.
                    It’s rather magical how much beauty we have in our own
                    bodies when we look at the talented work from Erika.
                </p>
                <p>
                    She made this beautiful art for an article about vulva
                    anatomy. The article is written in Portuguese, but if you’re
                    interested in it, check it out at the link:
                    https://www.uol.com.br/universa/reportagens-especiais/dia-v---leia-seus-labios/#page3
                </p>
                <p>Body, beauty and art inspiration for you all, loves!</p>
            </div>
        ),
    },
    {
        title: 'Free Your Vulva at a Festival',
        imgList: [77, 78],
        content: (
            <div>
                <h4>Free Your Vulva at a Festival</h4>
                <br />
                <p>
                    {' '}
                    🌿 WIN 1x TICKET TO MEDICINE FESTIVAL THAT STARTS TOMORROW
                    🌻
                    <br />
                </p>
                <p>
                    Still haven't been to a festival this year and happen to be
                    free this week? We're going to the Medicine Festival
                    together and we're giving away 1 ticket to one lucky winner!
                    Medicine, a festival for the people and the planet, is about
                    interweaving communities and bridging cultures 💚 It's
                    happening near Reading/UK from this Thursday to Sunday.
                    <br />
                </p>
                <p>
                    🚨 You have until midnight to enter 🚨
                    <br />
                </p>
                <p>
                    All you have to do to enter is...
                    <br />
                </p>
                <p>
                    🍀 Like this post and tag 1 friend
                    <br />
                    🌼 Follow us @freeyourvulva
                    <br />
                    🧘🏽‍♀️ And share this post in your stories
                    <br />
                </p>
                <p>
                    You have until Wednesday 17th 23h59 to enter, so get going!
                    Ts&Cs: Competition will run until Wed 17th Aug 23h59 and the
                    winner will be selected at random at midnight. The winner
                    will be contacted via dm by @freeyourvulva. 1 comment = 1
                    entry. Good luck 🙏
                </p>
            </div>
        ),
    },
    {
        title: 'Female Orgasm Day',
        imgList: [73, 74, 75, 76],
        content: (
            <div>
                <h4>Female Orgasm Day</h4>
                <br />
                <p>
                    Today is Female Orgasm Day and as much as we’d like to be
                    only celebrating what our powerful vulvas can do for us, we
                    must raise awareness of orgasm inequality. According to the
                    International Academy of S3x Research, heterosexual men
                    orgasm 95% of the time, gay men 89%, bisexual men 88%, gay
                    women 86%, bisexual women 66%, and heterosexual women 65%.
                    This striking gap of 30% between hetero men and women is
                    just one of the many symptoms of a deeply patriarchal
                    society, where women’s pleasure is not a priority., Laurie
                    Mintz Ph.D. said, “the orgasm gap is a cultural problem.”
                    And if you read more about it on On Psychology Today, you’ll
                    see that the roots of this problem spread widely and they
                    are not easily fixed. But we could tie the F.Y.V. manifesto
                    to every single cause, which is even more validation of the
                    importance of our work.
                </p>
                <p>
                    And the great news is that this is one inequality that you
                    can fight against with pleasure! According to the study,
                    women who orgasmed more frequently were more likely to:
                    <br />
                    -Receive more oral s3x
                    <br />
                    -Have a longer duration of s3x
                    <br />
                    -Be more satisfied with their relationship
                    <br />
                    -Ask for what they want in bed
                    <br />
                    -Wear s3xy lingerie
                    <br />
                    -Act out fantasies
                    <br />
                    -Praise their partner for s3x
                    <br />
                    -Call or email to tease about s3x
                    <br />
                    -Incorporate s3xy talk
                    <br />
                    -Try new s3xual positions
                    <br />
                    -Have anal stimulation
                    <br />
                    -Express love during s3x
                </p>
                <p>
                    A lot of the items listed in the study involve being with a
                    partner, but believe us when we say that nobody can please
                    you as you can. It takes a lot of self-care and
                    self-discovery, but it’s SO worth it. So honour this day by
                    dressing s3xy just for yourself, taking a sweet long time
                    m@sturbating yourself*, and maybe even acting out on
                    fantasies on your own mind! Let’s fill this gap, one juicy
                    orgasm at a time. 💦 *We recommend at least 20 minutes,
                    we’ll explain why in future posts 👀
                </p>
                <p>
                    Sources: https://tinyurl.com/studyOnOrgasmFrequency
                    https://tinyurl.com/psychologyTodayOrgasmGap
                </p>
                <p></p>
            </div>
        ),
    },
    {
        title: 'Laurie Melia Ceramics',
        imgList: [63, 64, 65, 66, 67, 68, 69, 70, 71, 72],
        content: (
            <div>
                <h4>Laurie Melia Ceramics</h4>
                <br />
                <p>
                    We’re delighted to find this unique artwork from
                    @laurie_melia_ceramics, a self-taught ceramic artist.
                </p>
                <p>
                    Unfortunately, her pieces are sold out, but we still love to
                    admire these stunning vulvas from the distance.
                </p>
            </div>
        ),
    },
    {
        title: 'It’s orgasm day',
        imgList: [57, 58, 59, 60, 61, 62],
        content: (
            <div>
                <h4>It’s orgasm day! The big O. La petit mort. The climax!</h4>
                <br />
                <p>
                    I bet you didn’t know some juicy facts about orgasms that we
                    found.
                </p>
                <p>
                    <span>
                        1. Less than 20% of women can orgasm from vaginal
                        penetration alone.
                    </span>{' '}
                    A kind reminder of why our friend clit is so important to
                    reach orgasm
                </p>
                <p>
                    <span>2. Women can have orgasms in their sleep.</span>{' '}
                    Especially when we’re on our fertile days
                </p>
                <p>
                    <span>3. Nipplegasms are real.</span> Because our body is
                    orgasmic! We can feel profound pleasure in different areas
                    of our body
                </p>
                <p>
                    <span>4. Orgasms can relieve pain.</span> Better than taking
                    pharmacy drugs, need to say more?
                </p>
                <p>
                    <span>5. Cannabis could improve orgasms for women.</span> If
                    it’s legal in your country, why not put it to test today?
                </p>
                <p>How will you celebrate orgasm day today? 😉</p>
                <p>
                    Sources:{' '}
                    <a
                        href="https://www.cosmopolitan.com/sex-love/a21937483/female-orgasm-facts/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Fact 1 to 3
                    </a>{' '}
                    /{' '}
                    <a
                        href="https://www.elle.com.au/health-fitness/orgasm-facts-19725"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Fact 4
                    </a>{' '}
                    /{' '}
                    <a
                        href="https://www.independent.co.uk/news/health/marijuana-sex-women-weed-cannabis-smoke-orgasm-a8867756.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Fact 5
                    </a>
                </p>
            </div>
        ),
    },
    {
        title: 'Vegan vulva raises £2.6 for charity',
        imgList: [52, 53, 55, 56],
        content: (
            <div>
                <h4>Vegan vulva raises £2.6 for charity</h4>
                <br />
                <p>
                    Paula not only organised a Bake Off at her work to raise
                    money for charity, but also baked herself the most delicious
                    and moist vulva vegan cake. And yes, the winning bidder
                    could and should eat everything, especially the clitoris,
                    which was literally the cherry on top!
                </p>
                <p>
                    Just seeing the conversations that this sparked was already
                    so rewarding and asking her colleagues - “Have you tasted my
                    vulva?” - was priceless. But on top of that, her cake alone
                    raised £240. And with all the other generous bakers
                    contributions, an incredible total of £2666 was raised on
                    that day!
                </p>
                <p>
                    This amount was equally divided between four charities that
                    @mvfglobal supports, one of them being @bloodygoodperiod.
                    Their mission is to fight for menstrual equity, giving
                    period products to those who can’t afford them and providing
                    menstrual education to those less likely to access it. Check
                    this awesome campaign they done to end shame about periods
                    https://www.youtube.com/watch?v=NYGy6ylWBQQ
                </p>
            </div>
        ),
    },
    {
        title: 'Vagina Museum',
        imgList: [45, 46, 47, 48, 49, 50, 51],
        content: (
            <div>
                <h4>To go: Vagina Museum</h4>
                <br />
                <p>
                    Any plans for the weekend? If you stay in London, why not
                    have some friends' fun time and go out for a brunch & visit
                    the first physical Vagina Museum? You can visit the main
                    exhibition or have a look at the events that are happening
                    there too!
                </p>
                <p>
                    Don’t miss this chance, you can book a free ticket on
                    www.vaginamuseum.co.uk and discover great stories about our
                    beautiful vulvas.
                </p>
                <p>
                    Their current exhibition is called “Periods: A Brief
                    History”: “Periods have been a taboo subject within society
                    for thousands of years. Over 800 million people menstruate
                    daily (Global Citizen, 2019) and over 50% of the global
                    population will menstruate at some point in their lives, yet
                    this natural process some bodies go through is something
                    still shrouded in shame and stigma to this day.” Plus, they
                    also have a permanent exhibition, called “from A to V”,
                    which covers four main areas: anatomy, health, vulva
                    diversity and activism.
                </p>
                <p>
                    And if you are interested in their events, this Friday
                    (29/Jul) and Saturday (30/Jul), they will be hosting a
                    comedy called “Plan V: The Joyful Cult of Pussy Worship”. It
                    starts at 7:30 PM and it’s only £5. Don’t miss that
                    gathering of true believers, worshippers of the sacred
                    pussy!
                </p>
                <p>
                    The Vagina Museum is located in Bethnal Green, east London.
                    You can book your tickets for the museum here:
                    https://www.vaginamuseum.co.uk/booktickets and for the
                    events here:
                    https://www.vaginamuseum.co.uk/events/eventscalendar
                </p>
            </div>
        ),
    },
    {
        title: 'Come Curious',
        imgList: [44],
        content: (
            <div>
                <h4>Come Curious</h4>
                <br />
                <p>
                    Looking for more open conversations about s3x? We highly
                    recommend our muses from @comecurious, Florence
                    @florencebark and Reed @reedamberx . They are the brains
                    behind the award-winning podcast F**ks Given, sharing their
                    experiences and opinion on sex, naked bodies and so much
                    more. Bea found out about this podcast while she was still
                    living in Brazil, a couple of years ago, and it keeps
                    getting better and better!
                </p>
                <p>
                    Removing the taboos around s3x, it talks about everything
                    sex-related. One of their goals is to stop the shame around
                    this important subject. We shouldn’t be ashamed to talk
                    about our desires and what makes us feel good and powerful,
                    right?
                </p>
                <p>
                    Not only you’ll learn about sex from other people’s points
                    of view (and here we’re talking about TRUE s3x, not
                    porn-acted sex), but also give some good laughs with these
                    two incredible women.
                </p>
                <p>Follow their podcast for some juicy content!</p>
                <p>
                    Available on{' '}
                    <a
                        href="https://open.spotify.com/show/4nQnVqQcEn4ptshlKjtdqo"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        Spotify
                    </a>{' '}
                    and{' '}
                    <a
                        href="https://open.spotify.com/show/https://podcasts.apple.com/gb/podcast/f-ks-given-by-comecurious/id1444176549"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        Apple
                    </a>
                </p>
            </div>
        ),
    },
    {
        title: 'Sexual orientation VS gender identification',
        imgList: [43],
        content: (
            <div>
                <h4>Sexual orientation VS gender identification</h4>
                <br />
                <p>
                    Understanding a person’s sexual orientation and gender
                    identification might seem difficult at first. Especially
                    when we’re young (and sometimes not so young too) and we’re
                    still trying to figure out what we like and what we don’t
                    like, what we are and what we are not. We’re complex human
                    beings. We are born and assigned at birth as female, male
                    and other/intersex. But we’re so much more than that! And
                    that’s the beauty of it.
                </p>
                <p>
                    We have the possibility to define ourselves in a spectrum of
                    gender identity, gender expression, and to who we’re
                    physically and emotionally attracted. There are no limits to
                    expressing ourselves within our truth.
                </p>
                <p>
                    If you’re new to those concepts and you’d like to learn more
                    about them, check out the cool infographic, the Gender
                    Unicorn, created by Trans Student Educational Resources,
                    2015{' '}
                    <a
                        href="http://www.transstudent.org/gender"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        “The Gender Unicorn”
                    </a>
                    .
                </p>
            </div>
        ),
    },
    {
        title: 'To watch: Good Luck to You, Leo Grande',
        imgList: [42],
        content: (
            <div>
                <h4>To watch: Good Luck to You, Leo Grande</h4>
                <br />
                <p>
                    We just went to the cinema to watch Good Luck to You, Leo
                    Grande, and what a brilliant movie it was! We wanted to make
                    sure you don’t miss out because this might be the last week
                    that it will be in the cinemas!
                </p>
                <p>
                    It’s a must-see for all women, of all ages. We laughed, we
                    cried, and for what we could hear, so did the whole
                    audience. Emma Thompson was stunning, picturing a generation
                    of women that lived their whole adult life disconnected to
                    their bodies and pleasure.
                </p>
                <p>
                    The movie talks about the story of Nancy Stokes, a
                    55-year-old widow, who is yearning for some adventure, human
                    connection, and exploring her sexual life (which hasn’t been
                    very thrilling for the past 30 years). It is almost entirely
                    filmed in a hotel room, so expect a lot of rich dialogues
                    and deep reflections that will make you think about the
                    movie for days and days.
                </p>
            </div>
        ),
    },
    {
        title: '40% of Britons mislabel the clitoris',
        imgList: [41],
        content: (
            <div>
                <h4>40% of Britons mislabel the clitoris</h4>
                <br />
                <p>
                    It’s no shame if you don’t know exactly where the clit is
                    either. Most women didn’t get a basic education in that
                    subject. Female anatomy is not something that we are thought
                    of when we were young (or even later in life). If you had
                    that at school, you may consider yourself privileged. And
                    there is sooo much taboo around it, which makes it even more
                    difficult to reach our girls with quality information.
                </p>
                <p>
                    But knowing your body, that’s power. For you to know who you
                    really are and have access to your inner strength, you have
                    to learn about every bit of yourself. It’s a matter of
                    health (how can you explain to your doctor what are you
                    feeling if you can’t label where the pain might be coming
                    from?). It’s a matter of self-knowledge. It’s a matter of
                    pleasure.
                </p>
                <p>
                    Want to know more about the survey (and where females’ 3
                    holes are located?), check this{' '}
                    <a
                        href="https://www.theguardian.com/lifeandstyle/2021/may/30/most-britons-cannot-name-parts-vulva-survey?CMP=Share_iOSApp_Other"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        article
                    </a>
                    .
                </p>
            </div>
        ),
    },
    {
        title: 'Viva la Vulva',
        imgList: [35, 36, 37, 38, 39, 40],
        content: (
            <div>
                <h4>Viva la Vulva</h4>
                <br />
                <p>
                    There’s a false image about what a vulva should look like.
                    It makes women think there is something wrong with their
                    bodies. It makes them feel ashamed. And that makes them
                    neglect their vulvas. Different is the norm. And we should
                    celebrate this diversity as a way to create body-positive
                    images to help women celebrate their bodies and feel proud
                    of their vulvas.
                </p>
                <p>
                    This was the concept for this incredible campaign designed
                    for Bodyform. Aside from its stunning production, they also
                    conducted global research delving into how women fell about
                    their vulvas. Among the 8000+ women interviewed:
                </p>
                <p>
                    - 68% don’t technically know what a vulva is (increasing to
                    73% in the UK) - 44% have felt embarrassed about their vulva
                    (increasing to a shocking 63% of 18-24 year olds interviewed
                    in the UK) - 50% feel pressure for their vulva to look a
                    certain way - 25% are utterly unaware that no two vulvas
                    should look the same
                </p>
                <p>
                    As upsetting as these results are, we hope they don’t get
                    you down. Instead, we hope they motivate you to keep sharing
                    knowledge to end shame and insecurity. And we can’t think of
                    anything more uplifting and empowering than watching the
                    campaign in full{' '}
                    <a
                        href="https://tinyurl.com/watchvivalavulva"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {' '}
                        here
                    </a>
                    .
                </p>
            </div>
        ),
    },
    {
        title: "Don't call the vulva a vagina",
        imgList: [32, 33, 34],
        content: (
            <div>
                <h4>
                    "No one calls the mouth a throat. So don't call the vulva a
                    vagina"
                </h4>
                <br />
                <p>
                    We must know the right terms to take full ownership of our
                    body - and use them without constraint to end shame and
                    insecurity around vulvas.
                </p>
                <p>
                    The vagina is merely the canal that connects the uterus to
                    the vulva. The almighty vulva encompasses all that meets the
                    eye (aka the external parts): the pubic mound, the labias,
                    the urethra and vagina openings, and last, but definitely
                    not least, the powerful clitoris.
                </p>
                <p>
                    Calling it a vagina is to reduce the vulva to a canal to
                    either give birth or receive a penis. Calling it a vagina is
                    one of the many symptoms of a deeply patriarchal society
                    that oppresses female pleasure and sexuality.
                </p>
                <p>
                    "Every time we use the word 'vagina' when we really mean
                    'vulva,' we're erasing the part that gives us the most
                    pleasure," Laurie Mintz, professor of counselling psychology
                </p>
                <p>
                    "Without knowing what is 'down there' and resorting to using
                    pet names or blushing every time we refer to our private
                    parts, just how comfortable can one be with one's sexuality,
                    much less sexual expression?" - Martha Lee, clinical
                    sexologist
                </p>
                <p>
                    If you feel that the word vulva sounds too anatomical, it’s
                    because it has never got the chance to live anywhere outside
                    a clinical context. Language does shape how we think and
                    feel. So let’s reclaim the word vulva and bring it to its
                    righteous place! A place where it only conveys its relevance
                    and power.
                </p>
            </div>
        ),
    },
    {
        title: 'To watch - The Feminists',
        imgList: [30, 31],
        content: (
            <div>
                <h4>The Feminists - On Netflix</h4>
                <br />
                <p>
                    This Netflix film covers a multitude of topics from
                    second-wave feminism, by following iconic and fearless women
                    that Cynthia MacAdams photographed in the 1970s. As hard as
                    it is to condense such complex issues into a 1,5h timeframe,
                    we felt they did a beautiful job of sharing light on
                    feminist awakening.
                </p>
                <p>
                    It’s inspiring to see women taking responsibility for
                    themselves.
                </p>
                <p>
                    It’s inspiring to see women feeling the freedom to be who
                    they are.
                </p>
                <p>It’s inspiring to see all that women have accomplished.</p>
                <p>And it’s inspiring to see all that still needs to change.</p>
            </div>
        ),
    },
    {
        title: 'It’s PRIDE month',
        imgList: [29],
        content: (
            <div>
                <h4>🌈 It’s PRIDE month!</h4>
                <br />
                <p>A month about acceptance. about equality.</p>
                <p>A month about being proud of our truest selves.</p>
                <p>A month about honouring the work of LGBTQ+ people.</p>
                <p>
                    A month about raising awareness of issues affecting the
                    LGBTQ+ community.
                </p>
                <p>
                    Be an active ally! Engage and be present for the LGBTQ+
                    community.
                </p>
                <br />
                <p>
                    For those who are in London, the main Pride Parade will take
                    place in Central London, on the 2nd of July. More info here:
                    https://prideinlondon.org/
                </p>
            </div>
        ),
    },
    {
        title: 'The Vagina Festival',
        imgList: [25, 26, 27, 28],
        content: (
            <div>
                <h4>The Vagina Festival</h4>
                <br />
                <p>
                    Created by Ellamae AKA @vavawomb_ and Emilie AKA
                    @myhairyvulvaandme, the festival aims to shake stigma
                    through storytelling, spoken word and visual arts.{' '}
                </p>
                <p>
                    And yes, it was a safe space to celebrate our bodies and our
                    VULVAS! We were so impressed by so many talented women that
                    went to the stage and presented their beautiful words!
                </p>
                <p>
                    The festival featured the incredible spoken word artist
                    Desree @dezziiee_, female comedy band Flat and the Curves
                    @flatnthecurves, gender-bending Drag Ting Shardeazy
                    Afrodesiak @shardeazy_afrodesiak and a bunch of amazing Open
                    Micers and Artists -@neelofer_nova nmspeaking @sbarks_
                    @thisisyvon_ @rhianbrookepoetry @zelhsan @t_monterisi
                    @isabellamckenziesanchez @fauifesto @bethanygdown
                    @leighanthracite @sophiasgaler @monikaradojevic @redrosegrl
                    @blackswannrobin
                </p>
            </div>
        ),
    },
    {
        title: 'Self-pleasure is self CARE',
        imgList: [23, 24],
        content: (
            <div>
                <h4>Self-pleasure is self CARE.</h4>
                <br />
                <p>
                    To celebrate the International Masturb@tion Day, here are
                    our TOP 5 benefits of masturb@tion (we could easily list
                    more than 5, but those are our favourite ones!):
                </p>
                <p>
                    <span>1.</span> Releases good hormones like endorphins,
                    serotonin and oxytocin (the cuddle hormone): a good dose of
                    those amazing hormones is all we need to cheer you up and
                    keep you in a good mood.
                </p>
                <p>
                    <span>2.</span> Reduces stress and anxiety: no pills, no
                    side effects (well, just the good ones). being focused on
                    your pleasure and nothing else is a great mindfulness
                    exercise. Forget the bills, forget the work issues. Just
                    concentrate on your D.I.Y time.
                </p>
                <p>
                    <span>3.</span> Allows you to fall into a deeper sleep more
                    quickly: much better than a glass of wine and it won’t
                    reduce the quality of your sleep like alcohol.
                </p>
                <p>
                    <span>4.</span> Reduces cramps when you’re on your period:
                    that’s right! The more pleasure you give to yourself, the
                    less pain your body signals your body will send. The next
                    time you feel that period pain, try playing the piano!
                </p>
                <p>
                    <span>5.</span> Helps you learn what you like in bed: the
                    last but, in our opinion, one of the main benefits. No one
                    will give you the best night in bed if you don’t know what
                    and how do you like to receive pleasure. Create your ménage
                    à moi moment and explore all your corners!
                </p>
            </div>
        ),
    },
    {
        title: 'Can porn be ethical?',
        imgList: [22],
        content: (
            <div>
                <h4>Can porn be ethical?</h4>
                <br />
                <p>
                    Yes! We’re not talking about the stereotypical mainstream
                    pornography that ignores women’s pleasure. We’re talking
                    about the eroticism of human sexuality and relationships,
                    people of different ages, races, sexuality, and gender,
                    different body shapes, and identities. Hot, great porn that
                    is made by women, for women.
                </p>
                <p>
                    That’s why for this #maysturbate we’d like to present to you
                    one of our favourite adult filmmakers: Erika Lust. Since
                    2014, Erika has been leading the revolution for adult cinema
                    that goes beyond traditional gender roles and tired
                    stereotypes, with a production that is ethical all the way.
                    In 2019, Erika was named as one of the BBC's 100 Most
                    Influential Women of the Year. That’s huge!
                </p>
                <p>
                    So, ever wondered what ethical porn looks like? Find more
                    about her wonderful work here:
                    https://erikalust.com/about/about-erika And if you don’t
                    know where to start, we want to recommend our personal
                    favourite - Female Pleasure Circle. It’s a stunning and very
                    artistic piece that made such an impact on our sexual
                    journeys.
                </p>
            </div>
        ),
    },
    {
        title: 'Sexual health',
        imgList: [20, 21],
        content: (
            <div>
                <h4>Sexual Health</h4>
                <br />
                <p>
                    As long as we live in a society where there is scarcity and
                    repression of pleasure, sexual health and well-being will
                    continue to be overlooked and undermined. But the good news
                    is that we live in times when we already have the validation
                    of its importance by authorities like the World Health
                    Organisation:
                </p>
                <p>
                    “Sexual health is fundamental to the overall health and
                    well-being of individuals, couples and families, and the
                    social and economic development of communities and
                    countries.
                </p>
                <p>
                    Sexual health, when viewed affirmatively, requires a
                    positive and respectful approach to sexuality and sexual
                    relationships, as well as the possibility of having
                    pleasurable and safe sexual experiences, free of coercion,
                    discrimination and violence.”
                </p>
                <p>
                    Bravo to all the doctors and scientists who worked so hard,
                    against all the prejudice, to prove and share this
                    knowledge. May is Masturbation Month, but do you know why?
                    It marked the protests when Surgeon General Joycelyn Elders
                    was fired for suggesting that masturbation should be taught
                    as a part of sex education. And that was 1995, so sadly not
                    long ago!
                </p>
                <p>
                    So let’s honour month and its history with the safest and
                    most pleasurable sex you can have. With yourself! If you
                    haven’t experienced that level of satisfaction on your own,
                    we encourage you to use this time to explore your body and
                    find out what gives you pleasure. Nobody can know and love
                    yourself as you do!
                </p>
                <p>
                    Font:
                    https://www.who.int/health-topics/sexual-health#tab=tab_1
                </p>
            </div>
        ),
    },
    {
        title: 'To watch: Principles of Pleasure',
        imgList: [15, 16, 17, 18, 19],
        content: (
            <div>
                <h4>To watch: Principles of Pleasure</h4>
                <br />
                <p>
                    We hope this Netflix series has gone mainstream already, but
                    in case it has gone unnoticed by you, here is why we highly
                    recommend The Principles of Pleasure.
                </p>
                <p>
                    You can see the first episode, Our Bodies, as a delicious
                    crash course on body anatomy, acceptance & awareness. It
                    touches on sex education, masturbation, sex toys, orgasm and
                    its health benefits.
                </p>
                <p>
                    As Dr Nicole Prause, PhD. explained, "there are absolutely
                    some conditions we likely can treat, as well or better, with
                    masturbation than we currently are with some pharmaceutical
                    drugs.”
                </p>
                <p>
                    Giving your body more sexual stimulation is not only a
                    journey towards more pleasure but also overall health. But
                    unfortunately, “when women think they’ve orgasmed, half the
                    time, they haven’t. Not necessarily because they’re faking
                    it, but because they truly never had one”.
                </p>
                <p>
                    F.Y.V. wants to help to change that by ending shame and
                    insecurity not only related to our vulvas, but our whole
                    bodies. We believe that’s the path to reveal women’s innate
                    potency and enjoy all that it can offer us.
                </p>
            </div>
        ),
    },
    {
        title: 'Vulva fact',
        imgList: [14],
        content: (
            <div>
                <h4>
                    Vulva fact: the labia minora can vary in width and length
                    from woman to woman and that’s totally normal!{' '}
                </h4>
                <br />
                <p>
                    They range from 1–5 centimetres wide and the shape also
                    varies from one side to the other (yes, they are not twin
                    sisters!). Grab a mirror and admire the beauty of your
                    unique vulva. Sometimes the labia minora is even bigger than
                    the labia majora (that’s common for 50% of women). This
                    scientific name can cause this confusion among women. It is
                    a very subjective issue to define the "normal" vulva, but
                    the most important thing is that you know that your labia is
                    unique and will be different from others, and that’s
                    completely NORMAL. <br></br>
                </p>
                <p>
                    Font: Andrej Vitushka, MD, PhD — Neonatal Intensive Care
                    Specialists, Medical Consultant at Flo. Link:
                    https://flo.health/menstrual-cycle/lifestyle/hygiene-and-beauty/labial-hypertrophy
                </p>
            </div>
        ),
    },
    {
        title: 'Vulva Casting by Vulvarium',
        imgList: [13],
        content: (
            <div>
                <h4>Vulva Casting by Vulvarium</h4>
                <br />
                <p>
                    Viktoria Krug is the brilliant austrian artist behind the
                    Vulvarium gallery and the vulva castings. Her project asks
                    us to re-evaluate what we consider “normal” or “beautiful”
                    and come to the conclusion that every single vulva is
                    perfect just the way it is. Have a look at her @vulvacasting
                    gallery to find out more about this beautiful work that
                    blends art, self-knowledge, experiences, feelings and
                    thoughts.
                </p>
                <p>
                    As Viki said on her website: “They are all different, they
                    are all powerful and their message is clear: Whatever your
                    experiences with your vulva are like – you are not alone!
                    There are thousands of women and people with vulvas out
                    there who feel, have felt, or might in the future feel just
                    like you do right now and your experience is normal and
                    worthy of being talked about. Only through dialogue can old
                    taboos be broken and new norms created so that one day we
                    can live in a society that accepts women and people with
                    vulvas as they are- and not as they want them to be.”
                </p>
            </div>
        ),
    },
];
