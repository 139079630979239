import React, { useEffect, useState } from 'react';

export const Modal = ({
    content,
    selectedImg,
    setSelectedImg,
    range,
    advent = false,
}) => {
    const [shownImg, setShownImg] = useState(content?.imgList[0]);
    const imgPath = advent
        ? require(`../Images/Thumbnails/Advent/Released/${shownImg}.png`)
        : require(`../Images/Thumbnails/instagram_post_${shownImg}.png`);
    const carousel = content.imgList.map((imgNo) => {
        return (
            <button
                className={`modal__carousel ${
                    shownImg === imgNo ? 'modal__carousel--selected' : ''
                }`}
                key={'carousel' + imgNo}
                data-cy={`carousel-img-${imgNo}`}
                onClick={() => setShownImg(imgNo)}
            />
        );
    });

    useEffect(() => {
        setShownImg(content.imgList[0]);
    }, [content, selectedImg]);

    return (
        <div className="modal">
            <div
                className="modal__bckg"
                onClick={() => setSelectedImg('')}
            ></div>
            {selectedImg > 0 && (
                <button
                    className="modal__button modal__button--previous"
                    aria-label="Previous"
                    data-cy="Previous"
                    onClick={() => {
                        setSelectedImg(selectedImg - 1);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="M28.05 36 16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9Z" />
                    </svg>
                </button>
            )}
            <div className={`modal__box ${advent ? 'modal__box--advent' : ''}`}>
                <div className="modal__close">
                    <button
                        className="button-reset-styles"
                        aria-label="Close"
                        data-cy="Close"
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelectedImg('');
                        }}
                    >
                        &times;
                    </button>
                </div>
                <div className="modal__content">
                    <div
                        className={`modal__img ${
                            advent ? 'modal__img--advent' : ''
                        }`}
                    >
                        <img
                            src={imgPath}
                            data-cy={`modal__img-${shownImg}`}
                            alt={content.title}
                        />

                        {content.imgList.length > 1 && (
                            <div display="flex">{carousel}</div>
                        )}
                    </div>
                    <div
                        className={`modal__text ${
                            advent ? 'modal__text--advent' : ''
                        }`}
                    >
                        {content.content}
                    </div>
                </div>
            </div>
            {selectedImg < range && (
                <button
                    className="modal__button modal__button--next"
                    aria-label="Next"
                    data-cy="Next"
                    onClick={() => {
                        setSelectedImg(selectedImg + 1);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                        <path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z" />
                    </svg>
                </button>
            )}
        </div>
    );
};
