import '../Styles.scss';
import { Header } from './header';
import { Manifesto } from './manifesto';

export const Home = () => {
    return (
        <>
            <Header />
            <Manifesto />
        </>
    );
};
