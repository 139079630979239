export const contentManifesto = [
    {
        title: '1. Praise vulva diversity by ending shame and insecurity',
        imgList: [12],
        content: (
            <div>
                <p>
                    <span>📜 F.Y.V. Manifesto - Our 3 Pillars</span>
                </p>
                <br />
                <p>
                    ✌🏽 1. Praise vulva diversity
                    <br></br>
                    by ending shame and insecurity
                </p>
                <p>
                    We want to provoke reflections on where our “normal and
                    beautiful” standards came from. Our aesthetic preferences
                    are intrinsically based on what we’ve been presented as
                    visually pleasant by society. And in a patriarchal and
                    phallic-centred society, the vulva suffers from
                    underrepresentation and misrepresentation.
                </p>
                <p>
                    Predominantly being reduced to “vagina”, even the word vulva
                    still needs to conquer its righteous space. We’re surrounded
                    by male genitalia, but we barely see any vulvas, and when we
                    do, it’s sadly an inaccurate version of it. We are not
                    presented with enough models of the wide diversity of vulval
                    possibilities. So unconsciously, we end up perceiving
                    ourselves through distorted lenses that don’t serve or
                    empower us. But we have the power to break through this
                    distortion and see our beautifully unique true selves.
                </p>
            </div>
        ),
    },
    {
        title: '2. Honour women’s sexuality by revealing our innate potency',
        imgList: [11],
        content: (
            <div>
                <p>
                    <span>📜 F.Y.V. Manifesto - Our 3 Pillars</span>
                </p>
                <br />
                <p>
                    👄 2. Honour women’s sexuality
                    <br></br>
                    by revealing our innate potency
                </p>
                <p>
                    Most women don’t understand their bodies... The few that do
                    and therefore celebrate it for what it is, see their sexual
                    liberation being condemned. We want to close the orgasm gap
                    caused by sexual ignorance and repression. We can free
                    ourselves from these societal constraints by embracing our
                    bodies for all the wonderful experiences they can provide
                    us, instead of their appearance. We want to raise awareness
                    not only about how a vulva looks like, but what it can give
                    us.
                </p>
                <p>
                    Let’s not limit ourselves to extending the body positive
                    movement to our vulvas, but also evolve from this to a body
                    neutrality point of view. To praise function over form.
                    Women have the only organ that is exclusively dedicated to
                    pleasure, the clitoris. And honouring our ability to have
                    multiple and powerful orgasms improves our physical and
                    mental health. Research shows that high sexual arousal can
                    aid chronic pain, sleep disorders, generalized anxiety and
                    even depression. So libido is not just sexual energy, it’s
                    vital energy. It’s a driving force that propels us to find
                    and be our truest selves.
                </p>
            </div>
        ),
    },
    {
        title: '3. Spark Societal Liberation by advocating empowerment & self-love',
        imgList: [10],
        content: (
            <div>
                <p>
                    <span>📜 F.Y.V. Manifesto - Our 3 Pillars</span>
                </p>
                <br />
                <p>
                    ✨ 3. Spark Societal Liberation
                    <br></br>
                    by advocating empowerment & self-love
                </p>
                <p>
                    As long as we’re not fully comfortable in our skin, we’re
                    not comfortable with ourselves. This inner conflict
                    manifests into insecurities in all spheres of our lives.
                    Most human suffering can be broken down into our inability
                    to love ourselves completely.
                </p>
                <p>
                    In an image-obsessed culture, we’re brainwashed into the
                    illusion that we’re our appearance. But our bodies are
                    nothing more than a vehicle for us to work on ourselves,
                    physically and emotionally. Embracing our bodies for what
                    they truly are and not what they look like is an essential
                    key to achieving self-love and unlocking our full potential.
                    And once we do, we start having a deeper connection with
                    ourselves and others. To accept others, we first need to
                    accept ourselves. Emerging from an individual into a
                    collective level, societal liberation starts within us.
                </p>
            </div>
        ),
    },
];
